import { Badge } from 'primereact/badge';
import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';

const FileView = ({ file, index, removeFile, progress }) => {
  const getFileIcon = () => {
    // eslint-disable-next-line default-case
    switch (file.name.split('.').pop()) {
      case 'mp3':
      case 'aac':
      case 'wav':
      case 'ogg':
      case 'flac':
        return 'pi-headphones';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
      case 'tiff':
      case 'webp':
      case 'avif':
        return 'pi-image';
      case 'doc':
      case 'docx':
        return 'pi-file-word';
      case 'pdf':
        return 'pi-file-pdf';
      case 'csv':
      case 'xls':
      case 'xlsx':
        return 'pi-file-excel';

      default:
        return 'pi-file';
    }
  };

  return (
    <div className='w-1/5 border rounded-lg shadow h-12 flex justify-start gap-x-3 items-center px-2 p-overlay-badge'>
      {progress ? (
        <div className='max-w-5 max-h-5'>
          <CircularProgressbar
            value={progress}
            styles={buildStyles({
              strokeLinecap: 'round',
              pathColor: `#46afd6`,
              trailColor: `#ffffff40`,
              height: 19,
              width: 19
            })}
          />
        </div>
      ) : (
        <i className={`pi ${getFileIcon()}`}></i>
      )}
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {file.name}
      </span>
      <Badge
        onClick={() => removeFile(index)}
        className='w-2 h-2 rounded-3xl flex justify-center py-3 text-center items-center text-xs bg-red-500 cursor-pointer'
        value={<i className='pi pi-times text-xs'></i>}
      ></Badge>
    </div>
  );
};

const mapState = (state) => ({
  progress: state.thread.progressPercentage
});

export default connect(mapState, {})(FileView);
