import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const assistantTypes = ["HR", "FINANCE", "MARKETING", "LEGAL"];

const useAssistantType = () => {
  const location = useLocation();
  let assistantType = useRef("");

  useEffect(() => {
    const currentLocation = location.pathname.split("/")[2];
    assistantType.current = assistantTypes.includes(currentLocation) ? currentLocation : "GENERAL";
  }, [location]);

  return assistantType.current;
}

export default useAssistantType;
