import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { processCombinedText } from '../utils/text';

const Markdown = ({ children }) => {
  const processedText = processCombinedText(children);

  return (
    <div className='w-11/12'>
      {processedText.map(({ direction, text }, index) => (
        <div key={index} className='mb-4 mt-1' style={{ direction }}>
          <ReactMarkdown
            className='prose markdown'
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {text}
          </ReactMarkdown>
        </div>
      ))}
    </div>
  );
};

export const mkTypes = {
  image_file: (fileId) =>
    `\n ![${fileId}](https://stage.7saip.com/api/assistant/getFile/${fileId})`,
  file_path: (fileId) =>
    `https://stage.7saip.com/api/assistant/getFile/${fileId}`
};

export default Markdown;
