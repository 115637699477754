import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Menu } from 'primereact/menu';
import FileView from './FileView';
import { connect } from 'react-redux';
import useDrivePicker from 'react-google-drive-picker';
import axios from 'axios';
import { toastError } from '../../utils/toastHelper';
import ConnectDBModal from '../connectDBModal';
import useAssistantType from '../../custom-hooks/useAssistantType';
import { THREAD_ACTION_TYPES } from '../../constants/thread.const';

const ChatBox = ({
  action,
  threadId,
  customClass,
  sendPrompt,
  newThread,
  loading,
  colors,
  userData,
  placeholder,
  generating,
  setGenerating,
  setLoading,
  parentComponent = '',
  setAction,
  chatModal,
  customPromptHandler,
  showInputMenu = true,
  onMicClick = () => { },
  children
}) => {
  const menu = useRef(null);
  const textareaRef = useRef(null);

  const [prompt, setPrompt] = useState('');
  const hiddenFileInput = useRef(null);
  const [files, setFiles] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openOracleModal, setOpenOracleModal] = useState(false);

  const [openPicker, authResult, config] = useDrivePicker();

  const assistantType = useAssistantType();

  const handleOpenPicker = () => {
    openPicker({
      clientId:
        '607020941629-sipufil9np2na2fkhrrd1g9sh50rsnbe.apps.googleusercontent.com',
      developerKey: 'AIzaSyBwC1cezsnjczalZLL54xRAQbc0apEos4o',
      viewId: 'DOCS',
      token: authResult?.access_token,
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: true,
      customScopes: [
        'https://www.googleapis.com/auth/drive',
        'https://www.googleapis.com/auth/drive.readonly',
        'https://www.googleapis.com/auth/drive.metadata.readonly'
      ],
      callbackFunction: (res) => {
        if (res.action === 'picked') {
          const selectedFiles = res.docs;
          setSelectedFiles(selectedFiles);
        }
      }
    });
  };

  const downloadFile = async (fileId, mimeType, name, access) => {
    let type = '';
    if (mimeType === 'application/vnd.google-apps.document') {
      type =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (mimeType === 'application/vnd.google-apps.spreadsheet') {
      type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else {
      type = mimeType;
    }
    let response = await axios.get(
      `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=${type}`,
      {
        headers: {
          Authorization: `Bearer ${access}`
        },
        responseType: 'blob'
      }
    );
    const mimeTypeList = {
      'audio/mpeg': 'mp3',
      'audio/aac': 'aac',
      'audio/wav': 'wav',
      'audio/ogg': 'ogg',
      'audio/flac': 'flac',
      'image/jpg': 'jpg',
      'image/jpeg': 'jpeg',
      'image/png': 'png',
      'image/gif': 'gif',
      'image/bmp': 'bmp',
      'image/tiff': 'tiff',
      'image/webp': 'webp',
      'image/avif': 'avif',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'docx',
      'application/pdf': 'pdf',
      'text/csv': 'csv',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'xlsx'
    };
    const blob = new Blob([response.data], { type: mimeType });
    const arrayBuffer = await blob.arrayBuffer();
    return new File([arrayBuffer], `${name}.${mimeTypeList[type]}`, {
      type: type
    });
  };
  const newItems = useMemo(() => {
    if (parentComponent === 'Falak') {
      return [
        {
          label: 'Oracle DB',
          icon: 'pi pi-database',
          command: () => {
            setOpenOracleModal(true);
          }
        },
        {
          label: 'Azure Blob Storage',
          icon: 'pi pi-microsoft',
          command: () => { }
        },
        {
          label: 'Upload files',
          icon: 'pi pi-upload',
          command: () => {
            hiddenFileInput.current.click();
          }
        }
      ];
    }
    return [
      {
        label: 'Oracle DB',
        icon: 'pi pi-database',
        command: () => {
          setOpenOracleModal(true);
        }
      },
      {
        label: 'Azure Blob Storage',
        icon: 'pi pi-microsoft',
        command: () => { }
      },
      {
        label: 'Postgres DB',
        icon: 'pi pi-database',
        command: () => { }
      },
      {
        label: 'Amazon S3',
        icon: 'pi pi-amazon',
        command: () => { }
      },
      {
        label: 'Jira',
        icon: 'pi pi-code',
        command: () => { }
      },
      {
        label: 'One Drive',
        icon: 'pi pi-microsoft',
        command: () => { }
      },
      {
        label: 'Gmail',
        icon: 'pi pi-envelope',
        command: () => { }
      },
      {
        label: 'Outlook',
        icon: 'pi pi-microsoft',
        command: () => { }
      },
      {
        label: 'Google Drive',
        icon: 'pi pi-google',
        command: () => {
          handleOpenPicker();
        }
      },
      {
        label: 'Upload files',
        icon: 'pi pi-upload',
        command: () => {
          hiddenFileInput.current.click();
        }
      }
    ];
  }, [parentComponent]);

  const handleUpload = (event) => {
    if (event.target.files.length + files.length < 5) {
      setFiles([...files, ...event.target.files]);
    } else {
      toastError('You can only upload 5 files');
    }
  };

  const handlePrompt = async () => {
    if (!prompt && files?.length === 0) {
      return;
    }

    setGenerating(true);

    if (customPromptHandler) {
      const lamaAction = chatModal === 'LLAMA' &&
        (files.length > 0 &&
          action !== THREAD_ACTION_TYPES.CHART
          ? THREAD_ACTION_TYPES.DOCUMENT : action
        );

      await customPromptHandler({ action: lamaAction, prompt, files });
    } else {
      await defaultPrompt();
    }

    setPrompt('');
    setFiles([]);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  }

  const defaultPrompt = async () => {
    await sendPrompt({
      userId: userData.id,
      files: files,
      text: prompt,
      threadId,
      ...(chatModal === 'LLAMA' && {
        action:
          files.length > 0 && action !== THREAD_ACTION_TYPES.CHART
            ? THREAD_ACTION_TYPES.DOCUMENT
            : action
      })
    });
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await handlePrompt();
    }
  };

  const handleInput = (event) => {
    const target = event.target;
    setPrompt(target.value);

    target.style.height = 'auto';
    target.style.height = `${Math.min(target.scrollHeight, 130)}px`;
  };

  const createNewThread = async () => {
    await newThread(assistantType);
  };

  useEffect(() => {
    if (threadId === 0) {
      createNewThread();
    }
  }, [threadId]);

  const handleSelectedFile = async (access) => {
    const file = await downloadFile(
      selectedFiles[0].id,
      selectedFiles[0].mimeType,
      selectedFiles[0].name,
      access
    );
    if (file) {
      setFiles([file]);
    }
  };

  useEffect(() => {
    if (authResult && selectedFiles.length) {
      setAccessToken(authResult.access_token);
      handleSelectedFile(authResult.access_token);
    }
  }, [authResult, selectedFiles]);

  const removeFile = (indexToRemove) => {
    let filesList = [...files];
    filesList.splice(indexToRemove, 1);
    setFiles([...filesList]);
  };

  return (
    <div
      className={
        'fixed bottom-0 left-0 right-0 py-2 flex flex-col justify-center shadow-lg ' +
        customClass
      }
      style={{ background: colors.mainColor }}
    >
      <ConnectDBModal
        isOpen={openOracleModal}
        setIsOpen={setOpenOracleModal}
        setGenerating={setGenerating}
        key={openOracleModal}
        prompt={prompt}
        setFiles={setFiles}
        parentComponent={parentComponent}
      />

      <div className='flex justify-start items-center w-2/4 mx-auto'>
        {showInputMenu &&
          <div className='w-1/12 flex flex-col justify-start items-start'>
            <div
              style={{ background: colors.iconsColor }}
              onClick={(event) => menu.current.toggle(event)}
              className={
                ' flex justify-center items-center w-10 h-10 border p-2 rounded-full text-center cursor-pointer hover:bg-secondary hover:text-gray-900 '
              }
            >
              <i className={'pi pi-paperclip'}></i>
            </div>
            <Menu
              model={newItems}
              popup
              ref={menu}
              className='w-auto text-xs'
              popupAlignment='right'
            />
          </div>
        }
        <div
          className='w-11/12 flex flex-col border rounded-lg shadow'
          style={{
            backgroundColor: colors.mainColor
          }}
        >
          <div className='flex justify-start items-center w-full min-h-14'>
            <textarea
              ref={textareaRef}
              style={{
                background: colors.mainColor,
                outline: 'none',
                resize: 'none'
              }}
              placeholder={placeholder}
              value={prompt}
              onChange={handleInput}
              onKeyPress={handleKeyPress}
              className='h-auto w-11/12 px-2 m-1 text-white rounded-lg'
              rows={1}
            />
            <div className={'w-1/12 flex justify-center'}>
              {generating ? (
                <button
                  onClick={() => {
                    setGenerating(false);
                    setLoading(false);
                  }}
                  className='flex justify-center items-center w-10 h-10 p-2 rounded-full text-center cursor-pointer hover:bg-secondary hover:text-gray-900 '
                >
                  <i className='pi pi-pause  mx-auto'></i>
                </button>
              ) : (
                <button
                  onClick={() => handlePrompt()}
                  className='flex justify-center items-center w-10 h-10 p-2 rounded-full text-center cursor-pointer hover:bg-secondary hover:text-gray-900 '
                >
                  {loading ? (
                    <i
                      className='pi pi-spin text-gray-400 pi-spinner mx-auto'
                      style={{ fontSize: '1rem' }}
                    ></i>
                  ) : (
                    <i className='pi pi-send  mx-auto'></i>
                  )}
                </button>
              )}

              <input
                style={{ display: 'none' }}
                type='file'
                ref={hiddenFileInput}
                value=''
                multiple={true}
                onChange={(event) => handleUpload(event)}
              />
            </div>
          </div>
          {files.length > 0 && (
            <div className='flex justify-start items-center w-full p-3 h-auto text-sm gap-x-3 overflow-x-auto horizontal-scroll-container'>
              {files.map((file, index) => {
                return (
                  <FileView
                    style={{ color: 'red' }}
                    key={index}
                    index={index}
                    file={file}
                    removeFile={(idx) => removeFile(idx)}
                  ></FileView>
                );
              })}
            </div>
          )}
        </div>
        <div className='w-1/12 flex flex-col justify-end items-end'>
          <div
            style={{ background: colors.iconsColor }}
            className='flex justify-center items-center w-10 h-10 border p-1 rounded-full text-center cursor-pointer'
            onClick={onMicClick}
          >
            <i className='pi pi-microphone text-lg' />
          </div>
        </div>
      </div>
      {chatModal === 'LLAMA' && (
        <div className='flex items-center space-x-2 mx-auto w-5/12 mt-2'>
          <input
            type='checkbox'
            id='chartCheckbox'
            className='h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500'
            onChange={(e) => {
              if (e.target.checked) {
                setAction(THREAD_ACTION_TYPES.CHART);
              } else {
                setAction(THREAD_ACTION_TYPES.CHAT);
              }
            }}
          />
          <label
            htmlFor='chartCheckbox'
            className='text-sm font-medium text-white'
          >
            Chart Analysis
          </label>
        </div>
      )}
      {children}
    </div>
  );
};

const mapState = (state) => ({
  userData: state.users.data,
  userInfo: state.users.userInfo,
  threadId: state.thread.threadId,
  loading: state.thread.loading,
  chatModal: state.thread.chatModal,
  action: state.thread.action
});

const mapDispatch = (dispatch) => ({
  sendPrompt: async (data) => await dispatch.thread.sendPrompt(data),
  newThread: async (assistantType, provider) =>
    await dispatch.thread.startNewThread(assistantType),
  setAction: (data) => dispatch.thread.setAction(data),
  setLoading: (data) => dispatch.thread.loadingReducer(data)
});

export default connect(mapState, mapDispatch)(ChatBox);
