import { useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Modal from 'react-modal';
import ConnectDBForm from './connectDBForm';
import http from '../utils/http';
import { toastError } from '../utils/toastHelper';
import { connect } from 'react-redux';
import { loadAndExtractZip } from '../utils/jszip';

Modal.setAppElement('#root');

const ConnectDBModal = ({
  isOpen,
  setIsOpen,
  setGenerating,
  sendPrompt,
  threadId,
  userData,
  setFiles,
  parentComponent = ''
}) => {
  const { colors } = useOutletContext();

  const aTagRef = useRef(null);

  const [tables, setTables] = useState([]);
  const [credentials, setCredentials] = useState({});
  const [tableNames, setTableNames] = useState([]);

  const handleTableClick = (table) => {
    setTableNames((prev) => {
      if (prev.includes(table)) {
        return prev.filter((name) => name !== table);
      } else {
        return [...prev, table];
      }
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const getTableContent = async (tableNames) => {
    try {
      const res = await http.post(
        'database/table-data',
        {
          ...credentials,
          tableNames
        },
        { responseType: 'blob' }
      );

      const blobFile = loadAndExtractZip(res.data);

      const zipFile = new File([blobFile], 'modified.zip', {
        type: 'application/zip'
      });

      setFiles([zipFile]);

      if (parentComponent !== 'Falak') {
        setGenerating(true);
        sendPrompt({
          files: [zipFile],
          threadId,
          userId: userData.id
        });
      }

      closeModal();
    } catch (error) {
      toastError("Can't get table content, please try again later");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: colors.transparentBackground
        },
        content: {
          backgroundColor: colors.mainColor
        }
      }}
      className='w-96 p-8 top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] absolute rounded-lg'
      contentLabel='Connect to DB'
    >
      {!tables.length ? (
        <ConnectDBForm
          setTables={setTables}
          setCredentials={setCredentials}
          closeModal={closeModal}
        />
      ) : (
        <div className='w-full px-6 mb-4 flex flex-col'>
          <p
            className='text-center mb-8 text-lg'
            style={{ color: colors.textColor }}
          >
            Tables
          </p>
          {tables.map((table) => (
            <div
              key={table}
              className={`${
                tableNames.includes(table) ? 'bg-[#4E4E4E]' : 'bg-[#2E2E2E]'
              } hover:bg-[#4E4E4E] text-white my-4 p-4 text-center rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg cursor-pointer`}
              onClick={() => handleTableClick(table)}
            >
              {table}
            </div>
          ))}
          <button
            className='bg-[#2E2E2E] text-white px-4 py-2 mt-4 rounded-lg shadow-md hover:bg-[#4E4E4E] transition-all duration-300 transform hover:scale-105'
            onClick={() => getTableContent(tableNames)}
          >
            Add files
          </button>
        </div>
      )}
      <a ref={aTagRef} className='hidden'>
        download
      </a>
    </Modal>
  );
};

const mapState = (state) => ({
  threadId: state.thread.threadId,
  userData: state.users.data
});

const mapDispatch = (dispatch) => ({
  sendPrompt: async (data) => await dispatch.thread.sendPrompt(data),
  sendFalakPrompt: async (data) => await dispatch.thread.sendFalakPrompt(data)
});

export default connect(mapState, mapDispatch)(ConnectDBModal);
