import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import http from "../utils/http";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { object, string, number } from "yup";
import { toastError } from "../utils/toastHelper";

const ConnectDBForm = ({ setTables, setCredentials }) => {
  const { colors } = useOutletContext();

  const [loading, setLoading] = useState(false);

  const connectSchema = object().shape({
    username: string().required("User Name is a required field"),
    databaseIdentifier: string().required("SID is a required field"),
    port: number().required(),
    host: string().required(),
    password: string().required(),
  });

  const handleConnect = async (body) => {
    setLoading(true);

    try {
      const res = await http.post("database/get-tables", body);

      setCredentials(body);
      setTables(res.data);
    } catch (error) {
      toastError("Failed to connect");
    }
    setLoading(false);
  };

  const errorMessage = (message) => (
    <p className="text-red-700 h-0">{message}</p>
  );

  return (
    <Formik
      initialValues={{
        port: "",
        host: "",
        databaseIdentifier: "",
        username: "",
        password: "",
      }}
      validationSchema={connectSchema}
      onSubmit={handleConnect}
    >
      <Form className="flex flex-col mt-2 w-full items-center lg:px-0 container m-auto">
        <Field
          className="h-10 w-72 px-2 text-white outline-none border rounded-lg"
          style={{ background: colors.mainColor, color: colors.textColor }}
          name="port"
          type="number"
          placeholder="Port"
        />
        <ErrorMessage name="port" render={errorMessage} />

        <Field
          className="h-10 w-72 px-2 mt-7 text-white outline-none border rounded-lg"
          style={{ background: colors.mainColor, color: colors.textColor }}
          name="host"
          type="text"
          placeholder="Host"
        />
        <ErrorMessage name="host" render={errorMessage} />

        <Field
          className="h-10 w-72 px-2 mt-7 text-white outline-none border rounded-lg"
          style={{ background: colors.mainColor, color: colors.textColor }}
          name="databaseIdentifier"
          type="text"
          placeholder="SID"
        />
        <ErrorMessage name="databaseIdentifier" render={errorMessage} />

        <Field
          className="h-10 w-72 px-2 mt-7 text-white outline-none border rounded-lg"
          style={{ background: colors.mainColor, color: colors.textColor }}
          name="username"
          type="text"
          placeholder="User Name"
        />
        <ErrorMessage name="username" render={errorMessage} />

        <Field
          className="h-10 w-72 px-2 mt-7 text-white outline-none border rounded-lg"
          style={{ background: colors.mainColor, color: colors.textColor }}
          name="password"
          type="password"
          placeholder="Password"
          autoComplete="one-time-code"
        />
        <ErrorMessage name="password" render={errorMessage} />

        <button className="w-40 p-2 mt-7 bg-[#040e14] border border-[#86e4dc] rounded-lg text-white">
          Connect
          {loading && (
            <i className="pi pi-spin text-white pi-spinner relative left-2 top-0.5 text-base"></i>
          )}
        </button>
      </Form>
    </Formik>
  );
};

export default ConnectDBForm;
