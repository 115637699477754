import JSZip from 'jszip';

export const loadAndExtractZip = async (file) => {
  const zip = new JSZip();
  const zipContent = await zip.loadAsync(file);
  const files = {};

  zipContent.forEach(async (relativePath, fileEntry) => {
    if (fileEntry.name.endsWith('.csv')) {
      const content = await fileEntry.async('text');
      files[fileEntry.name] = content;
    }
  });

  Object.keys(files).forEach((fileName) => {
    zip.file(fileName, files[fileName]);
  });

  const newZipBlob = await zip.generateAsync({ type: 'blob' });
  return newZipBlob;
}
