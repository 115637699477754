import StreamingAvatar, { AvatarQuality, StreamingEvents, TaskMode, TaskType, VoiceEmotion } from "@heygen/streaming-avatar";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toastError } from "../utils/toastHelper";
import Chatbox from "../components/thread/chatbox";
import { useOutletContext } from "react-router-dom";

const Najm = () => {
  const avatar = useRef(null);
  const mediaStream = useRef(null);
  const isMicOpen = useRef(false);
  const [stream, setStream] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [loading, setLoading] = useState(false);

  const { colors } = useOutletContext();

  const [isUserTalking, setIsUserTalking] = useState(false); //for future use

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream;
      mediaStream.current.onloadedmetadata = () => {
        mediaStream.current.play();
      };
    }
  }, [mediaStream, stream]);

  useEffect(() => {
    startSession();
    return () => {
      avatar.current?.closeVoiceChat();
    }
  }, []);

  useEffect(() => {    
    if (!generating) {
      avatar.current?.interrupt();
    }
  }, [generating]);

  const fetchHydrogenAccessToken = async () => {
    try {
      const token = await axios.post(
        "https://api.heygen.com/v1/streaming.create_token",
        {},
        {
          headers: {
            "x-api-key": process.env.REACT_APP_HEYGEN_API_KEY,
          },
        },
      );

      return token.data.data.token;
    } catch (error) {
      toastError('Something went wrong please try again later')
      return '';
    }
  }

  const startSession = async () => {
    setLoading(true);
    const newToken = await fetchHydrogenAccessToken();
    if (!newToken) {
      return;
    }

    avatar.current = new StreamingAvatar({
      token: newToken,
    });
    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
      console.log("Avatar started talking", e);
      setGenerating(true);
      setLoading(true);
    });
    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
      console.log("Avatar stopped talking", e);
      setLoading(false);
    });
    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      console.log("Stream disconnected");
      endSession();
    });
    avatar.current?.on(StreamingEvents.STREAM_READY, (event) => {
      console.log(">>>>> Stream ready:", event.detail);
      setStream(event.detail);
    });
    avatar.current?.on(StreamingEvents.USER_START, (event) => {
      console.log(">>>>> User started talking:", event);
      setIsUserTalking(true);
    });
    avatar.current?.on(StreamingEvents.USER_STOP, (event) => {
      console.log(">>>>> User stopped talking:", event);
      setIsUserTalking(false);
    });

    try {
      await avatar.current.createStartAvatar({
        quality: AvatarQuality.Low,
        avatarName: 'Wayne_20240711', // TODO: add avatarName
        knowledgeId: 'c480abac15884fcaa6a2ad9b0ef5fcb0', // To be changed later on.
        voice: {
          rate: 1.5,
          emotion: VoiceEmotion.FRIENDLY,
        },
        language: 'en',
      });
    } catch (error) {
      console.error("Error starting avatar session:", error);
    } finally {
      setLoading(false);
    }
  }

  async function endSession() {
    await avatar.current?.stopAvatar();
    setStream(null);
  }

  const sendPrompt = async ({ prompt }) => {
    setLoading(true);
    if (!avatar.current) {
      return;
    }
    await avatar.current.speak({
      text: prompt,
      taskType: TaskType.TALK,
      taskMode: TaskMode.SYNC
    });
    setLoading(false);
  }

  const toggleVoiceMode = async () => {
    isMicOpen.current = !isMicOpen.current;
    if (isMicOpen.current) {
      await avatar.current?.startVoiceChat();
    } else {
      await avatar.current?.closeVoiceChat();
    }
  }

  return (
    <div className="h-screen flex flex-col justify-between items-center pt-12">
      <div className="w-[800px] max-w-full max-h-full">
        {stream &&
          <video
            className="w-full h-full"
            ref={mediaStream}
            autoPlay
            playsInline
          >
            <track kind="captions" />
          </video>
        }
      </div>

      <Chatbox
        colors={colors}
        customClass=''
        placeholder='Talk With Najm...'
        generating={generating}
        setGenerating={setGenerating}
        customPromptHandler={sendPrompt}
        parentComponent='Falak'
        inputOnly={true}
        showInputMenu={false}
        onMicClick={toggleVoiceMode}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
};

export default Najm;
