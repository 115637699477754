import { createModel } from "@rematch/core";
import http from "../utils/http";
import { replaceMessagesFilesPaths } from "../utils/text";
import { THREAD_ACTION_TYPES } from "../constants/thread.const";
import { delay } from "../utils/delay";

const ThreadStore = createModel()({
  name: "thread",
  state: {
    thread: {},
    historyList: [],
    threadId: 0,
    loading: false,
    showWelcomeMessage: true,
    lastMessage: "",
    lastStream: "",
    showTypingEffect: false,
    location: "",
    showStreaming: false,
    chartData: null,
    hitsResults: null,
    action: THREAD_ACTION_TYPES.CHAT,
    chatModal: "",
    progressPercentage: 0,
  },
  reducers: {
    hideWelcomeMessageReducer(state, payload) {
      return {
        ...state,
        showWelcomeMessage: payload,
      };
    },
    emptyThreadReducer(state, payload) {
      return {
        ...state,
        thread: {},
        threadId: 0,
      };
    },
    loadingReducer(state, payload) {
      return {
        ...state,
        loading: payload,
      };
    },
    sendPromptReducer(state, payload) {
      return {
        ...state,
        thread: payload,
      };
    },
    getHistoryReducer(state, payload) {
      return {
        ...state,
        historyList: payload,
      };
    },
    setThreadIdReducer(state, payload) {
      return {
        ...state,
        threadId: payload,
      };
    },
    setLastMessageReducer(state, payload) {
      return {
        ...state,
        lastMessage: payload,
      };
    },
    setLastStreamReducer(state, payload) {
      return {
        ...state,
        lastStream: payload,
      };
    },
    showTypingEffectReducer(state, payload) {
      return {
        ...state,
        showTypingEffect: payload,
      };
    },
    showStreamingReducer(state, payload) {
      return {
        ...state,
        showStreaming: payload,
      };
    },
    changeLocationReducer(state, payload) {
      return {
        ...state,
        location: payload,
      };
    },
    startNewThreadReducer(state, payload) {
      return {
        ...state,
        threadId: payload,
        thread: {},
      };
    },
    setChartData(state, payload) {
      return {
        ...state,
        chartData: payload,
      };
    },
    clearChartData(state, payload) {
      return {
        ...state,
        chartData: null
      }
    },
    setHitsResults(state, payload) {
      return {
        ...state,
        hitsResults: payload,
      };
    },
    clearHitsResults(state, payload) {
      return {
        ...state,
        hitsResults: null
      }
    },
    setAction(state, payload) {
      return {
        ...state,
        action: payload
      }
    },
    setChatModal(state, payload) {
      return {
        ...state,
        chatModal: payload
      }
    },
    setProgress(state, payload) {
      return {
        ...state,
        progressPercentage: payload,
      };
    },
    clearProgress(state, payload) {
      return {
        ...state,
        progressPercentage: 0
      }
    }
  },
  effects: (dispatch) => ({
    async emptyThread(payload, state) {
      dispatch.thread.emptyThreadReducer();
    },
    async setLastStream(payload, state) {
      dispatch.thread.setLastStreamReducer(payload);
    },
    async changeLocation(payload, state) {
      dispatch.thread.changeLocationReducer(payload);
    },
    async startNewThread(payload, state) {

      const { users, thread } = state;
      if (users.logedIn) {
        const res = await http.post(
          `/thread?assistantType=${payload}`,
          {
            provider: thread.chatModal,
            lngOwnerId: users.data.id
          },
          {
            headers: { 'content-type': 'multipart/form-data' }
          }
        );

        dispatch.thread.startNewThreadReducer(res.data.id);
      }
    },
    async sendPrompt(payload, state) {
      dispatch.thread.showStreamingReducer(true);
      dispatch.thread.loadingReducer(true);
      dispatch.thread.setLastMessageReducer(payload.text);
      dispatch.thread.hideWelcomeMessageReducer(false);
      const formData = new FormData();

      if (payload.files.length > 0) {
        for (const file of payload.files) {
          formData.append("files", file);
        }
      }

      if (payload.text) {
        formData.append("message", payload.text);
      }
      if (state.thread.location) {
        formData.append("assistantType ", state.thread.location);
      }

      let res = {};

      try {
        res = await http.post(
          `/thread/${payload.threadId}/message?runAssistant=false`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: progressEvent => {
              const percentage = Math.floor(progressEvent.loaded / progressEvent.total * 100);
              dispatch.thread.setProgress(percentage);
            },
          }
        );

        dispatch.thread.clearProgress();
        dispatch.thread.sendPromptReducer(res.data);
        dispatch.thread.showTypingEffectReducer(true);
        dispatch.thread.loadingReducer(false);
      } catch {
        dispatch.thread.loadingReducer(false);
      }
    },
    async sendFalakPrompt(payload, state) {
      const { users } = state;
      const formData = new FormData();

      dispatch.thread.showStreamingReducer(true);
      dispatch.thread.loadingReducer(true);
      dispatch.thread.setLastMessageReducer(payload.prompt);
      dispatch.thread.hideWelcomeMessageReducer(false);

      try {
        if (payload.files.length > 0) {
          for (const file of payload.files) {
            formData.append("file", file);
          }
          await http.post(
            `/files/upload?lngOwnerId=${users.data.id}`,
            formData
          );
        }
        // Applying a delay between the upload and the search on Falak
        await delay(2500);

        if (payload.prompt) {
          const searchRes = await http.get(
            `/files/search?lngOwnerId=${users.data.id}&query=${payload.prompt}&semanticRatio=${payload.semanticRatio}`,
          );
          dispatch.thread.setHitsResults(searchRes.data)
        }

        dispatch.thread.showTypingEffectReducer(true);
        dispatch.thread.loadingReducer(false);
      } catch {
        dispatch.thread.loadingReducer(false);
      }
    },
    async getHistory(payload, state) {
      const { users } = state;
      const history = await http.get(`thread?lngOwnerId=${users.data.id}&assistantType=${payload}`);
      dispatch.thread.getHistoryReducer(history.data);
    },
    async getThread(payload, state) {
      dispatch.thread.showStreamingReducer(false);
      dispatch.thread.setThreadIdReducer(payload.id);
      dispatch.thread.showTypingEffectReducer(false);

      const thread = await http.get(`thread/${payload.id}/message?assistantType=${payload.assistantType}`);
      replaceMessagesFilesPaths(thread.data.messages);

      if (payload?.userMessage) {
        thread.data.messages = [...thread.data.messages, payload.userMessage];
      }

      dispatch.thread.sendPromptReducer(thread.data);
      dispatch.drawer.openDrawer(false);
    },
  }),
});

export default ThreadStore;
